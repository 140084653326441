export const dateFormatter = (input) => {

    const date = new Date(input)
    const formatted = new Intl.DateTimeFormat('default', {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    }).format(date);

    return formatted


};

export const imageURL = 'https://mikroapp.testwebspace.co.uk/assets/images/inventory/'
