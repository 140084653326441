import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Dashboard from "./Pages/dashboard";
import Login from "./Pages/login";
import Products from "./Pages/products";
import MyOrders from "./Pages/my-orders";
import Checkout from "./Pages/checkout";
import AddressBook from "./Pages/address-book";
import AddressUpdate from "./Pages/address-book-update";  
import OrderDetails from "./Pages/order-details";
import Division from "./Pages/division";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/products/:divisionID",
    element: <Products />,
  },
  {
    path: "/orders/:customerID",
    element: <Products />,
  },
  {
    path: "/division/:divisionID",
    element: <Division />,
  },
  {
    path: "/my-orders",
    element: <MyOrders />,
  },
  {
    path: "/checkout",
    element: <Checkout />,
  },
  {
    path: "/address-book",
    element: <AddressBook />,
  },
  {
    path: "/order/details/:orderID",
    element: <OrderDetails />,
  },
  {
    path: "/address/update/:addressID",
    element: <AddressUpdate />,
  },
]);

const queryClient = new QueryClient();


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={router} />
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
