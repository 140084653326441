import React from "react";
import { useGetRequest, sendPostRequest, sendGetRequest } from "../Client";

function DivisionSidebarSub(props) {
  const { status, data } = useGetRequest(
    "divisions/subcategories/" +
      process.env.REACT_APP_ACCOUNT_ID +
      "/" +
      props.parent,
    {}
  );

  if (status === "fetched") {
    return (
      <div>
        {data?.categories.map((item) => (
          <>
            <li className="mt-1">
              <div
                onClick={() => 
                  {
                    props.setSubCategory(item.categoryID)
                    props.setCategory(0)
                    props.setCategoryName("")
                    props.setSubCategoryName(item.categoryName)
                  }
                }
                className="hover:bg-blue-100 text-xs ml-4 cursor-pointer"
              >
                {item.categoryName}
              </div>
            </li>
          </>
        ))}
      </div>
    );
  }
}

export default DivisionSidebarSub;
