import React from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

const navigation = {
  main: [
    // { name: 'Link 1', href: '#' },
    // { name: 'Link2', href: '#' },
    // { name: 'Link 3', href: '#' },
    // { name: 'Link 4', href: '#' },
  ]

}

function Footer() {
  return (
    <footer className="bg-gray-900">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-10 sm:py-10 lg:px-8">
        <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="pb-6">
              <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-white-900">
                {item.name}
              </a>
            </div>
          ))}
        </nav>

        <p className="mt-10 text-center text-xs leading-5 text-gray-500">
          &copy; 2024 MI-KRO. All rights reserved. v1.0
        </p>
      </div>
    </footer>
  );
}

export default Footer;
