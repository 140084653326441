import { React, Fragment, useState } from "react";
import { useGetRequest, sendPostRequest, sendGetRequest } from "../Client";
import DivisionSidebarSub from "../Components/DivisionSidebarSub";

function DivisionSidebar(props) {
  const { status, data } = useGetRequest(
    "divisions/categories/" +
      process.env.REACT_APP_ACCOUNT_ID +
      "/" +
      props.divisionID,
    {}
  );

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  if (status === "fetched") {
    return (
      <nav
        aria-label="Sidebar"
        className="mt-12 mr-12 flex flex-1 flex-col bg-gray-100 p-4 rounded-3xl"
      >
        <ul role="list" className="space-y-1">
          {data?.categories.map((item) => (
            <>
              <li key={item.name}>
                <a
                  onClick={() => {
                    props.setCategory(item.categoryID)
                    props.setCategoryName(item.categoryName)
                    props.setSubCategory(0)
                    props.setSubCategoryName("")
                  }}
                  className={classNames(
                    item.current
                      ? "bg-gray-50 text-indigo-600 cursor-pointer"
                      : "text-gray-700 hover:bg-blue-100 hover:text-grey-600",
                    "group flex gap-x-3 rounded-md p-1 pl-3 text-sm font-semibold leading-6 cursor-pointer"
                  )}
                >
                  {item.categoryName}
                </a>
              </li>

              <DivisionSidebarSub
                parent={item.categoryID}
                setSubCategory={props.setSubCategory}
                setCategory={props.setCategory}
                setSubCategoryName={props.setSubCategoryName}
                setCategoryName={props.setCategoryName}
              />
            </>
          ))}
        </ul>
      </nav>
    );
  }
}

export default DivisionSidebar;
