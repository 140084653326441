import { React, Fragment, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { sendPostRequest, useGetRequest, sendGetRequest } from "../Client";
import { dateFormatter } from "../config";
import { imageURL } from "../config";

function OrderDetails() {
  const { orderID } = useParams();

  // loading orders
  const { data: orderDetails, isLoading } = useQuery({
    queryFn: () => sendGetRequest("orders/" + orderID, {}),
    queryKey: ["orders"],
  });

  const { status: statusItems, data: dataItems } = useGetRequest(
    "orders/items/" + orderID,
    {}
  );

  const sumChargeTotal = () => {
    let total = 0;

    dataItems?.items.forEach((a) => {

      if (a.price2 > 0) {
        total += Number(a.quantityOrdered * a.unitPrice) + Number(a.price2);
      } else {
        total += Number(a.quantityOrdered * a.unitPrice);
      }
    });

    return (Math.round(total * 100) / 100).toFixed(2);
  };

  if (!isLoading && statusItems === "fetched") {
    return (
      <>
        <Navbar />

        <div className="bg-gray-0">
          <div className="mx-auto max-w-2xl pt-16 sm:px-6 sm:py-16 lg:max-w-7xl lg:px-8">
            <div className="space-y-2 px-4 sm:flex sm:items-baseline sm:justify-between sm:space-y-0 sm:px-0">
              <div className="flex flex-col">
                <div>
                  <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Order #{orderID}
                  </h1>
                </div>
              </div>
              <p className="text-sm text-gray-600">
                Order Date:{" "}
                <time
                  dateTime="2021-03-22"
                  className="font-medium text-gray-900"
                >
                  {orderDetails?.order[0]?.orderDate.length > 0
                    ? dateFormatter(orderDetails?.order[0]?.orderDate)
                    : null}
                </time>
              </p>
            </div>

            <div className="border rounded border-gray-200 px-4 py-6 sm:px-6 lg:p-8 mt-5">
              <div className="mt-6" aria-hidden="true">
                <div className="overflow-hidden rounded-full bg-gray-200">
                  <div
                    className="h-2 rounded-full bg-indigo-600"
                    style={{
                      width:
                        orderDetails?.order[0]?.orderStatusID == 2
                          ? "15%"
                          : orderDetails?.order[0]?.orderStatusID == 1
                          ? "100%"
                          : orderDetails?.order[0]?.orderStatusID == 8
                          ? "100%"
                          : "50%",
                    }}
                  />
                </div>
                <div className="mt-6 hidden grid-cols-3 text-sm font-medium text-gray-600 sm:grid">
                  <div className="text-center">Order placed</div>
                  <div className="text-center">Processing</div>
                  <div className="text-center">Shipped</div>
                </div>
              </div>
            </div>

            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          ></th>

                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Code
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Description
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Min/Max
                          </th>

                          {process.env.REACT_APP_ACCOUNT_ID === "2" ? (
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Handling Fee
                            </th>
                          ) : null}

                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Unit Price
                          </th>

                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Ordered
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Shipped
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {dataItems?.items?.map((items) => (
                          <tr key={items.orderItemID}>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <img
                                className="h-11 w-11 rounded-full"
                                src={imageURL + items.image}
                                alt=""
                              />
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {items.productCode}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {items.description}
                            </td>

                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {items.minmax}
                            </td>

                            {process.env.REACT_APP_ACCOUNT_ID === "2" ? (
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                ${items.price2}
                              </td>
                            ) : null}

                            {process.env.REACT_APP_ACCOUNT_ID === "2" ? (
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                ${items.unitPrice}
                              </td>
                            ) : null}

                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {items.quantityOrdered}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {/* {items.quantityShipped} */}
                            </td>

                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              $
                              {(
                                (items.quantityOrdered *
                                  items.unitPrice *
                                  100) /
                                100
                              ).toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10">
              <div className="bg-gray-100 px-4 py-6 sm:rounded-lg sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8 lg:py-8">
                <dl className="grid grid-cols-2 gap-6 text-sm sm:grid-cols-2 md:gap-x-8 lg:col-span-7">
                  <div>
                    <dt className="font-medium text-gray-900">Shipping To:</dt>
                    <dd className="mt-3 text-gray-500">
                      <span className="block">
                        {orderDetails?.order[0]?.orderName}
                      </span>
                      <span className="block mt-1">
                        {orderDetails?.order[0]?.orderAddress1}
                      </span>
                      <span className="block mt-1">
                        {orderDetails?.order[0]?.orderCity},{" "}
                        {orderDetails?.order[0]?.orderState}{" "}
                        {orderDetails?.order[0]?.orderZip}
                      </span>
                    </dd>
                  </div>

                  <div>
                    <dt className="font-medium text-gray-900">
                      Tracking Number:
                    </dt>
                    <dd className="mt-3 text-gray-500">
                      <div className="underline hover:text-indigo-500 ">
                        {" "}
                        <Link to="https://www.fedex.com/fedextrack/?trknbr=782296566318&trkqual=2460167000~782296566318~FX">
                          {orderDetails?.order[0]?.tracking}
                        </Link>
                      </div>
                    </dd>
                  </div>
                </dl>

                <dl className="mt-8 divide-y divide-gray-200 text-sm lg:col-span-5 lg:mt-0">
                  <div className="flex items-center justify-between pb-4 mr-5">
                    <dt className="text-gray-600">Subtotal</dt>
                    <dd className="font-medium text-gray-900">
                      ${sumChargeTotal()}
                    </dd>
                  </div>
                  <div className="flex items-center justify-between py-4 mr-5">
                    <dt className="text-gray-600">Shipping</dt>
                    <dd className="font-medium text-gray-900">$0.00</dd>
                  </div>
                  <div className="flex items-center justify-between pt-4 mr-5">
                    <dt className="font-medium text-gray-900">Order total</dt>
                    <dd className="font-medium text-indigo-600">
                      {" "}
                      <div>${sumChargeTotal()}</div>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default OrderDetails;
