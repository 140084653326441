import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Fragment, useState } from "react";
import { sendGetRequest, sendPostRequest, useGetRequest } from "../Client";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import StatesDropdown from "../Components/States";
import { Link } from "react-router-dom";

function AddressBook() {
  const queryClient = useQueryClient();

  const states = StatesDropdown();

  // loading inventory
  const { data: addresses, isLoading } = useQuery({
    queryFn: () => sendGetRequest("addresses/list", {}),
    queryKey: ["addresses"],
  });

  // adding address
  const [addressName, setAddressName] = useState();
  const [companyName, setCompanyName] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState();
  const [addressLine1, setAddressLine1] = useState();
  const [addressLine2, setAddressLine2] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zip, setZip] = useState();
  const [country, setCountry] = useState("US");

  // adding inventory
  const addressAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
    addedNotyf();
  };

  const { mutateAsync: doAddMutation } = useMutation({
    mutationFn: (data) => {
      return addressAdd(data.path, { ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["addresses"] });
    },
  });

  // confirm delete
  const areYouSure = (id) => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        doDeleteMutation(id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const addedNotyf = () => {
    notyf.success("Address added");
  };

  const deletedNotyf = () => {
    notyf.success({ message: "Address deleted", background: "#de3716" });
  };

  // deleting inventory
  const addressDelete = async (id) => {
    const res = await sendPostRequest("addresses/delete", {
      addressID: id,
    });
  };

  const { mutateAsync: doDeleteMutation } = useMutation({
    mutationFn: (id) => {
      return addressDelete(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["addresses"] });
      deletedNotyf();
    },
  });

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  if (!isLoading) {
    return (
      <>
        <Navbar />

        <div className="mb-20 mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-5">
            Address Book
          </h1>

          <div class="grid grid-cols-3 gap-2">
            <div class="col-span-2 p-2">
              <div className="w-1/2 inline-block mb-3">
                <label
                  htmlFor="addressName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Address Name*
                </label>
                <div className="mt-1 w-[90%]">
                  <input
                    type="text"
                    id="addressName"
                    name="addressName"
                    onChange={(e) => {
                      setAddressName(e.target.value);
                    }}
                    className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="w-1/2 inline-block">
                <label
                  htmlFor="companyName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Company Name
                </label>
                <div className="mt-1 w-[90%]">
                  <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                    className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="w-1/2 inline-block mb-3">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Contact First Name*
                </label>
                <div className="mt-1 w-[90%]">
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="w-1/2 inline-block">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Contact Last Name*
                </label>
                <div className="mt-1 w-[90%]">
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="w-1/2 inline-block mb-3">
                <label
                  htmlFor="phoneNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone Number
                </label>
                <div className="mt-1 w-[90%]">
                  <input
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                    className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="w-1/2 inline-block">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email*
                </label>
                <div className="mt-1 w-[90%]">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="w-1/2 inline-block mb-3">
                <label
                  htmlFor="addressLine1"
                  className="block text-sm font-medium text-gray-700"
                >
                  Address Line 1*
                </label>
                <div className="mt-1 w-[90%]">
                  <input
                    type="text"
                    id="addressLine1"
                    name="addressLine1"
                    onChange={(e) => {
                      setAddressLine1(e.target.value);
                    }}
                    className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="w-1/2 inline-block mb-3">
                <label
                  htmlFor="addressLine2"
                  className="block text-sm font-medium text-gray-700"
                >
                  Address Line 2
                </label>
                <div className="mt-1 w-[90%]">
                  <input
                    type="text"
                    id="addressLine2"
                    name="addressLine2"
                    onChange={(e) => {
                      setAddressLine2(e.target.value);
                    }}
                    className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="w-1/2 inline-block">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  City*
                </label>
                <div className="mt-1 w-[90%]">
                  <input
                    type="text"
                    id="city"
                    name="city"
                    required
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                    className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="w-1/2 inline-block mb-3">
                <div className="mt-2 w-[90%]">
                  <select
                    id="state"
                    name="state"
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                    className="h-[60%] w-full block rounded-md border border-transparent py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value=""></option>
                    {states.map((state) => (
                      <option value={state.code}>{state.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="w-1/2 inline-block">
                <label
                  htmlFor="zip"
                  className="block text-sm font-medium text-gray-700"
                >
                  ZIP Code
                </label>
                <div className="mt-1 w-[90%]">
                  <input
                    type="text"
                    id="zip"
                    name="zip"
                    onChange={(e) => {
                      setZip(e.target.value);
                    }}
                    className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              {/* <div className="w-1/2 inline-block mb-3">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Country*
                </label>
                <div className="mt-2 w-[90%]">
                  <select
                    id="country"
                    name="country"
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                    className="h-[60%] w-full block rounded-md border border-transparent py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option>United States</option>
                    <option>Canada</option>
                    <option>Mexico</option>
                  </select>
                </div>
              </div> */}

              <div className="w-full inline-block">
                <div className="mt-1 w-[40%] ml-[75%]">
                  <button
                    href="#"
                    onClick={async () => {
                      if (
                        email == "" ||
                        addressLine1 == "" ||
                        city == "" ||
                        zip == "" ||
                        state == "" ||
                        phoneNumber == "" ||
                        country == ""
                      ) {
                        notyf.error("Please fill in all required fields");
                        return;
                      }

                      try {
                        await doAddMutation({
                          path: "addresses/add",
                          body: {
                            addressName,
                            companyName,
                            firstName,
                            lastName,
                            phoneNumber,
                            email,
                            addressLine1,
                            addressLine2,
                            city,
                            state,
                            zip,
                            country,
                          },
                        });
                        // props.setOpen(false);
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                    className="w-full rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last sm:ml-6 sm:w-auto"
                  >
                    Add Address
                  </button>
                </div>
              </div>
            </div>

            {addresses?.addresses.length === 0 && (
              <div className="bg-gray-50 text-sm h-20 rounded-xl text-center py-8">
                -- No Addresses Added --
              </div>
            )}

            <div class="col-span-1">
              {addresses?.addresses?.map((address) => (
                <div className="border bg-gray-50 mb-2">
                  <div className="flex flex-row justify-between">
                    <dl className="flex flex-wrap mt-2">
                      <div className="flex-auto pl-6">
                        <dt className="text-sm font-semibold leading-6 text-gray-900">
                          {address.addressName}
                        </dt>
                        <dt className="text-sm font-semibold leading-6 text-gray-900">
                          {address.companyName}
                        </dt>
                        <dt className="text-sm font-semibold leading-6 text-gray-900">
                          {address.address1}
                        </dt>
                        <dt className="text-sm font-semibold leading-6 text-gray-900">
                          {address.city}, {address.state} {address.zip}
                        </dt>

                        <dt className="text-sm font-semibold leading-6 text-gray-900">
                          {address.country}
                        </dt>
                        <dt className="text-sm font-semibold leading-6 text-gray-900 mb-2">
                          {address.email}
                        </dt>
                      </div>
                    </dl>

                    <div className="mt-2 ml-20 text-sm text-amber-500 hover:cursor-pointer hover:text-amber-700">
                      <Link to={`/address/update/${address.addressID}`}>Edit</Link>
                    </div>

                    <div
                      onClick={() => {
                        if (areYouSure(address.addressID)) {
                          addressDelete(address.addressID);
                        }
                      }}
                      className="mt-2 mr-2 text-sm text-red-500 hover:cursor-pointer hover:text-red-700"
                    >
                      Delete
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default AddressBook;
